import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { SolutionCategoryModel } from '../../modules/admin/modules/solution/classes/category/model/solution.category.model';
import { CatBtnComponent } from '../cat-btn/cat-btn.component';
import { TranslationModule } from '@ckmk/angular';

@Component({
  selector: 'sol-cat-btn',
  imports: [CatBtnComponent, TranslationModule],
  templateUrl: './sol-cat-btn.component.html',
  styleUrl: './sol-cat-btn.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolCatBtnComponent implements OnInit, OnChanges {
  @Input({
    required: true,
  })
  public category!: SolutionCategoryModel;

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
