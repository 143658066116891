<ng-container>
  <cat-btn
    [title]="category.title | trans"
    [description]="category.shortDescription | trans"
    [colorLeft]="category.data?.leftColor"
    [colorRight]="category.data?.rightColor"
    [picture]="category.cover?.frontUrl()"
    [path]="['/solutions', category.slug]"
  >
    {{ category.title | trans }}
  </cat-btn>
</ng-container>
